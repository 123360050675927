<template>
  <v-dialog v-bind="$attrs" v-on="$listeners">
    <v-card class="pa-4 d-flex justify-center align-center" v-if="currentSensor">
      <div style="width: 65%; min-height: 380px;">
        <apexchart
          :ref="`graficaArea${currentSensor.codigo}`"
          type="area"
          :options="currentSensor.graficaArea.options"
          :series="currentSensor.graficaArea.series"
        >
        </apexchart>
      </div>
      <div class="pa-5" style="width: 35%;">
        <div class="grafica-input my-2">
          <p class="grafica-input__label mb-2">Desde: </p>
          <v-menu
            v-model="showDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(currentSensor.graficaArea.desde)"
                append-icon="mdi-calendar"
                readonly
                class="grafica-input__input"
                outlined
                dense
                hide-details
                locale="es"
                v-bind="attrs"
                v-on="on"
                :loading="currentSensor.graficaArea.loading"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currentSensor.graficaArea.desde"
              no-title
              @input="showDateMenu = false; actualizarGraficaArea(currentSensor)"
            ></v-date-picker>
          </v-menu>

        </div>
        <div class="grafica-input my-2">
          <p class="grafica-input__label mb-2">Hasta: </p>
          <v-menu
            v-model="showDateMenuHasta"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(currentSensor.graficaArea.hasta)"
                append-icon="mdi-calendar"
                readonly
                class="grafica-input__input"
                outlined
                dense
                hide-details
                locale="es"
                v-bind="attrs"
                v-on="on"
                :loading="currentSensor.graficaArea.loading"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currentSensor.graficaArea.hasta"
              no-title
              @input="showDateMenuHasta = false; actualizarGraficaArea(currentSensor)"
            ></v-date-picker>
          </v-menu>

        </div>
        <div class="grafica-input my-2">
          <p class="grafica-input__label mb-2">Sensores: </p>
          <v-select
            dense hide-details
            class="grafica-input__input"
            outlined
            v-model="currentSensor.sensoresGrafica"
            :items="sensores"
            item-text="nombre"
            item-value="codigo"
            no-data-text="No hay mas sensores disponibles"
            multiple
            chips
            deletable-chips
            hide-selected
            @input="actualizarGraficaArea(currentSensor)"
          >
          </v-select>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ChartLinealRegistrosSensorModal",
  props: ['currentSensor', 'sensores'],
  inheritAttrs: false,
  data() {
    return {
      showDateMenu: false,
      showDateMenuHasta: false,
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split(' ')[0].split('-')
      return `${day}/${month}/${year}`
    },
    actualizarGraficaArea(sensor) {
      this.$emit('updateChart', sensor)
    }
  },
  mounted() {
    this.$root.$on('updatedSensorSeries', () => {
      this.$refs[`graficaArea${this.currentSensor.codigo}`]?.updateSeries(this.currentSensor.graficaArea.series)
    })
  }

}
</script>

<style scoped>

</style>
